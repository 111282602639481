<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    routeback="/fornecedor/cadastro/cliente"
  >
    <FormField
      label="Nome / Razão social"
      v-model="form.name"
      :validation="$v.form.name"
    />
    <FormField
      label="CPF/CNPJ"
      v-model="form.cpf_cnpj"
      :validation="$v.form.cpf_cnpj"
      :mask="cpfMask"
      :disabled="disableEditCpfCnpj"
      @input="disableFieldCpfCnpj"
    />
    <FormField
      label="E-mail"
      v-model="form.email"
      :validation="$v.form.email"
      :persistenthint="true"
    />
    <v-btn
      v-if="!form.email_verificado"
      small
      text
      color="red"
      class="mt-n10 font-weight-bold"
      style="text-transform: none !important;"
      @click="sendVerificacao('email')"
    >
      Clique aqui para reenviar verificação de e-mail ao cliente!
    </v-btn>
    <FormDatePicker
      v-model="form.birthday"
      :validation="$v.form.birthday"
      label="Data nascimento"
      v-show="!disableFieldBirthday"
    />
    <FormField
      label="Telefone"
      v-model="form.phone"
      :validation="$v.form.phone"
      :mask="maskPhone"
      :persistenthint="true"
    >
      <template v-slot:append>
        <a :href="'tel:' + form.phone" target="_blank" class="phone-btn">
          <v-icon>fas fa-phone</v-icon>
        </a>
        <a
          :href="'https://wa.me/+55' + phoneNumber"
          target="_blank"
          class="phone-btn"
          style="margin-left: 10px"
        >
          <v-icon>fab fa-whatsapp</v-icon>
        </a>
      </template>
    </FormField>
    <v-btn
      v-if="!form.telefone_verificado"
      small
      text
      color="red"
      class="mt-n10 font-weight-bold"
      style="text-transform: none !important;"
      @click="sendVerificacao('sms')"
    >
      Clique aqui para reenviar verificação de telefone ao cliente!
    </v-btn>
    <FormField
      label="ID do ERP"
      v-model="form.id_client_erp"
      :validation="$v.form.id_client_erp"
    />

    <v-row justify="center">
      <v-col cols="12" xs="12" sm="5" md="4" lg="3" xl="3">
        <FormSwitch v-model="form.can_sell_to" label="Cadastro aprovado" />
      </v-col>
      <v-col cols="12" xs="12" sm="5" md="4" lg="3" xl="3">
        <FormSwitch
          v-model="form.uses_product_multiple"
          label="Usa múltiplo de produto"
        />
      </v-col>
      <v-col cols="12" xs="12" sm="5" md="4" lg="3" xl="3">
        <FormSwitch
          v-model="form.uses_single_address"
          label="Usa endereço único (somente endereço primário)"
        />
      </v-col>
    </v-row>

    <FormSelect
      v-if="showFormaPagamentoComponent"
      label="Vincular formas de pagamento"
      v-model="selectedPaymentMethods"
      :options="paymentMethods"
      :multiple="true"
    />
    <FormSelect
      v-if="showSegmentoComponent"
      label="Segmentos"
      v-model="selectedSegments"
      :options="segments"
      :multiple="true"
    />
    <FormSelect
      v-if="showGrupoPrecosComponent"
      label="Grupo de Preços"
      v-model="form.grupo_precos_erp"
      :options="priceGroups"
      :clearable="true"
    />
    <div v-if="ignoraGrupoPrecoErp" style="margin-bottom:10px;">
      <small style="color: var(--danger-color);">
        Ao desmarcar essa opção a manutenção do grupo de preço do cliente será
        manual. Poderão ocorrer divergências de preço nos pedidos, sob
        responsabilidade do usuário logado.
      </small>
    </div>
    <FormSwitch
      v-model="form.permitir_alteracao_campos_erp"
      label="Permitir que a integração modifique o grupo de preços"
      val="grupo_precos_erp"
    />

    <div v-if="form.grupo_precos_erp" class="codigo_grupo_preco">
      <span class="codigolabel"
        >Código do grupo: {{ form.grupo_precos_erp }}</span
      >
      <input type="hidden" id="testing-code" :value="form.grupo_precos_erp" />
      <FormButton
        class="botaocopiar"
        text="Copiar"
        @click.stop.prevent="copiar()"
      />
    </div>
    <FormAutocomplete
      label="Atendente padrão"
      placeholder="Pesquisa pelo nome do atendente"
      :options="optionsAtendente"
      @search="onSearchAtendente"
      v-model="form.atendente_padrao"
      useTemplate
    >
      <template v-slot:item="{ item }">
        <div class="atendente-123 d-flex flex-column align-start">
          <p style="margin-bottom:0!important">{{ item.text }}</p>
        </div>
      </template>
    </FormAutocomplete>
    <FormCheckbox
      label="Habilitar edição de limites"
      v-model="enableEditLimit"
    />

    <FormField
      v-if="supportClientLimit"
      label="Limite de crédito disponível"
      v-model="form.credit_limit_available"
      :disabled="!enableEditLimit"
      :money="form.credit_limit_available === null ? null : moneyMask"
    />
    <FormField
      v-if="supportClientLimit"
      label="Limite de crédito total"
      v-model="form.credit_limit_total"
      :disabled="!enableEditLimit"
      :money="form.credit_limit_total === null ? null : moneyMask"
    />
    <LineDivider><h4>Características do cliente</h4></LineDivider>
    <ClienteCaracteristicas
      v-model="form.characteristics"
      :validation="$v.form.characteristics"
    />
    <div v-if="idcliente" class="reset-senha-container">
      <FormButton
        text="Resetar senha"
        type="button"
        :disabled="disableResetPassword"
        @click="sendResetPassword"
      />
    </div>
    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import ClienteCaracteristicas from './ClienteFormCaracteristica'
import FormCheckbox from '@/components/form/FormCheckbox'
import FormDatePicker from '@/components/form/FormDatePicker'
import FormSwitch from '@/components/form/FormSwitch'
import FormSelect from '@/components/form/FormSelect'
import FormButton from '@/components/form/FormButton'
import FormField from '@/components/form/FormField'
import LineDivider from '@/components/LineDivider'
import SidePopup from '@/components/SidePopup'
import FormAutocomplete from '@/components/form/FormAutocomplete'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import { mapGetters } from 'vuex'
import { required, requiredIf, email } from 'vuelidate/lib/validators'
import mixinUtils from '@/mixins/utils'
import * as _ from 'lodash'
import ApiWeb from '../../../service/api-web'

import {
  CLIENTE_CREATE,
  CLIENTE_UPDATE,
  CLIENTE_LOAD,
  CLIENTE_CREATE_BIND_INFO,
  CLIENTE_RESET_PASSWORD,
  CLIENTE_LOAD_SEGMENTS,
  CLIENTE_VERIFICACAO
} from '@/store/actions/cliente'
export default {
  name: 'ClienteForm',
  mixins: [mixinUtils],
  components: {
    ClienteCaracteristicas,
    LineDivider,
    SidePopup,
    FormSelect,
    FormField,
    FormSwitch,
    FormDatePicker,
    FormCheckbox,
    FormButton,
    FormAutocomplete
  },
  validations: {
    form: {
      name: { required },
      cpf_cnpj: { required },
      email: { required, email },
      phone: {
        required: requiredIf(function() {
          return !this.bypassPersonalDataValidation
        })
      },
      birthday: {
        required: requiredIf(function() {
          return this.isCpf && !this.bypassPersonalDataValidation
        })
      },
      characteristics: {
        $each: {
          descricao: {
            required: requiredIf(function(cara) {
              return cara.obrigatorio && !cara.descricao
            })
          }
        }
      }
    }
  },
  data: () => ({
    mode: 'add',
    form: null,
    selectedPaymentMethods: [],
    selectedSegments: [],
    disableResetPassword: false,
    moneyMask: {
      decimal: ',',
      thousands: ' ',
      prefix: '',
      suffix: '',
      default: '',
      precision: 2,
      masked: false
    },
    enableEditLimit: false,
    atendentesResult: [],
    camposIgnoraveisIntegracao: ['grupo_precos_erp'],
    hasEmailConfig: false,
    hasCelularConfig: false,
    disableEditCpfCnpj: false,
    disableFieldBirthday: false
  }),
  created() {
    const idRoute = this.$route.params.id
    this.form = this.cleanForm
    if (idRoute !== 'novo') {
      this.mode = 'edit'
      this.getFornecedor()
      this.getData(idRoute)
    } else {
      this.editable = true
    }
  },
  computed: {
    ...mapGetters([
      'getFornecedorTag',
      'getListCaracteristicasCliente',
      'getFornecedorId',
      'moduloFornecedorAtivo'
    ]),
    title() {
      return this.mode == 'edit' ? 'Editar cliente' : 'Novo cliente'
    },
    paymentMethods() {
      return (this.$store.getters.getFormasPagamento || []).map(f => {
        return this.getDataToSelect(f, 'description')
      })
    },
    priceGroups() {
      return (this.$store.getters.getGruposPreco || []).map(g => {
        return {
          value: _.get(g, 'codigo_grupo_preco'),
          text: _.get(g, 'titulo')
        }
      })
    },
    segments() {
      return (this.$store.getters.getSegmentos || []).map(s =>
        this.getDataToSelect(s, 'nome')
      )
    },
    cleanForm() {
      return {
        idcliente: '',
        cpf_cnpj: '',
        name: '',
        birthday: '',
        email: '',
        id_client_erp: '',
        can_sell_to: false,
        id_supplier_erp: '',
        phone: '',
        uses_product_multiple: false,
        tem_senha: false,
        password: null,
        selectedPriceGroup: {},
        credit_limit_available: null,
        credit_limit_total: null,
        characteristics: [],
        ignorar_alteracao_erp: [],
        permitir_alteracao_campos_erp: []
      }
    },
    usesGenericForeignDocument() {
      return this.getFornecedorTag['uses-generic-foreign-document']
    },
    supportClientLimit() {
      return this.getFornecedorTag['support-client-limit']
    },
    bypassPersonalDataValidation() {
      return this.getFornecedorTag['bypass-personal-data-validation']
    },
    cpfMask() {
      return this.usesGenericForeignDocument
        ? null
        : ['###.###.###-##', '##.###.###/####-##']
    },
    isCpf() {
      let cleanDocument = this.form.cpf_cnpj.replace(/\D/g, '')
      return cleanDocument.length > 11 ? false : true
    },
    idcliente() {
      return (this.form && this.form.idcliente) || null
    },
    maskPhone() {
      return this.form.phone && this.form.phone.replace(/\D/g, '').length > 11
        ? ['(##) ####-####', '(##) #####-####', '## (##) #####-####']
        : ['(##) ####-####', '(##) #####-####']
    },
    phoneNumber() {
      return this.form.phone && this.form.phone.replace(/\D/g, '')
    },
    optionsAtendente() {
      return (this.atendentesResult || []).map(item => ({
        text: this.formataNome(item),
        value: item.idusuario
      }))
    },
    ignoraGrupoPrecoErp() {
      return this.form.ignorar_alteracao_erp.includes('grupo_precos_erp')
    },
    showSegmentoComponent() {
      return this.moduloFornecedorAtivo([
        ModulesEnum.WEB_COMMERCE,
        ModulesEnum.COMMERCE_APP
      ])
    },
    showGrupoPrecosComponent() {
      return this.moduloFornecedorAtivo([
        ModulesEnum.ORCAMENTO_WEB,
        ModulesEnum.ORCAMENTO_APP,
        ModulesEnum.WEB_COMMERCE,
        ModulesEnum.COMMERCE_APP,
        ModulesEnum.VENDAMAIS_WEB,
        ModulesEnum.VENDAMAIS_APP
      ])
    },
    showFormaPagamentoComponent() {
      return this.moduloFornecedorAtivo([
        ModulesEnum.PAINEL_CLIENTE,
        ModulesEnum.WEB_COMMERCE,
        ModulesEnum.COMMERCE_APP,
        ModulesEnum.VENDAMAIS_WEB,
        ModulesEnum.VENDAMAIS_APP
      ])
    }
  },
  methods: {
    getFornecedor() {
      ApiWeb.v1.fornecedor
        .obtemDadosFornecedor(this.getFornecedorId)
        .then(resp => {
          this.hasCelularConfig = resp.data.config_celular ? true : false
          this.hasEmailConfig = resp.data.config_email ? true : false
        })
    },
    usesSingleAddress(cliente) {
      return cliente.tag && cliente.tag.includes('uses-single-address')
    },
    getData(id) {
      this.$store
        .dispatch(CLIENTE_LOAD, { idcliente: id })
        .then(resp => this.loadData(resp))
    },
    formataNome(item) {
      if (item.nome == item.nomeusuario) {
        return item.nome
      }
      return `${item.nome} | ${item.nomeusuario}`
    },
    mapCaracteristicas(cliente) {
      if (!(_.get(cliente, 'caracteristicas') || []).length) return []
      const carac = _.get(cliente, `caracteristicas`) || []
      const caracList = this.getListCaracteristicasCliente
      return (
        carac.map(item => {
          const _c = caracList.find(
            c => c.idcaracteristica === item.idcaracteristica
          )
          const value =
            _c && _c.tipo === 'boolean'
              ? `${item.descricao}` === 'true'
              : item.descricao
          return {
            text: item.nome,
            idcaracteristica: item.idcaracteristica,
            obrigatorio: _c && _c.obrigatorio,
            value: item.idcaracteristica,
            tipo: _c && _c.tipo,
            descricao: value
          }
        }) || []
      )
    },
    async loadData(cliente) {
      this.form = {
        ...cliente,
        characteristics: this.mapCaracteristicas(cliente),
        birthday: this.getBirthday(cliente.birthday),
        uses_single_address: this.usesSingleAddress(cliente),
        credit_limit_total: this.prepareNumberDecimalToString(
          cliente.credit_limit_total
        ),
        credit_limit_available: this.prepareNumberDecimalToString(
          cliente.credit_limit_available
        ),
        permitir_alteracao_campos_erp: _.difference(
          this.camposIgnoraveisIntegracao,
          cliente.ignorar_alteracao_erp
        )
      }

      this.selectedSegments = await this.getSegments(cliente)
      this.selectedPaymentMethods = this.getSelectedPaymentMethods(cliente)
      this.disableEditCpfCnpj = cliente.cpf_cnpj ? true : false
      this.disableFieldCpfCnpj(cliente.cpf_cnpj)
    },
    getSelectedPaymentMethods(cliente) {
      return this.$store.getters.getFormasPagamento.filter(f => {
        return (
          cliente.formas_pagamento &&
          cliente.formas_pagamento.includes(f.idformapagto)
        )
      })
    },
    getDataToSelect(value, field) {
      return value
        ? {
            value,
            text: value[field]
          }
        : null
    },
    getBirthday(birthday) {
      return birthday ? birthday.split(' ')[0] : ''
    },
    getSelectedSegments(data) {
      let selectedSegments = []
      if (data) {
        let segmento
        data.forEach(d => {
          segmento = this.$store.getters.getSegmentos.find(s => {
            return s.idsegmentocliente === d.idsegmentocliente
          })
          selectedSegments.push(segmento)
        })
      }
      return selectedSegments
    },
    getSegments(cliente) {
      return new Promise(resolve => {
        this.$store
          .dispatch(CLIENTE_LOAD_SEGMENTS, { idcliente: cliente.idcliente })
          .then(data => resolve(this.getSelectedSegments(data)))
      })
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      let payload = {
        ...this.form,
        credit_limit_total: this.prepareNumberDecimal(
          this.form.credit_limit_total
        ),
        credit_limit_available: this.prepareNumberDecimal(
          this.form.credit_limit_available
        )
      }
      this.mode === 'add'
        ? this.handleSubmit(
            CLIENTE_CREATE,
            payload,
            'Cliente cadastrado com sucesso'
          )
        : this.handleSubmit(
            CLIENTE_UPDATE,
            payload,
            'Cliente atualizado com sucesso'
          )
    },
    handleSubmit(action, payload, msg) {
      this.$store.dispatch(action, payload).then(result => {
        const payloadExtra = {
          formasPagamento: this.selectedPaymentMethods,
          segmentos: this.selectedSegments
        }
        if (!result)
          return this.$vueOnToast.pop('success', 'Erro ao atualizar cliente')
        this.vincularFormasPagamento(result.item_id, payloadExtra).then(() => {
          this.$vueOnToast.pop('success', msg)
          this.$router.replace('/fornecedor/cadastro/cliente')
          this.$emit('close')
        })
      })
    },
    vincularFormasPagamento(idcliente, data) {
      return new Promise(resolve => {
        const idsFormaPagamento = data.formasPagamento.map(f => f.idformapagto)
        const idsSegmentos = data.segmentos.map(s => {
          return { idsegmentocliente: s.idsegmentocliente }
        })
        const body = {
          idcliente,
          idsFormaPagamento,
          idsSegmentos
        }
        this.$store.dispatch(CLIENTE_CREATE_BIND_INFO, body).then(resolve)
      })
    },
    sendResetPassword() {
      if (this.idcliente && !this.disableResetPassword) {
        this.disableResetPassword = true
        this.$store
          .dispatch(CLIENTE_RESET_PASSWORD, { idcliente: this.idcliente })
          .then(() => {
            this.$vueOnToast.pop(
              'success',
              'Redefinição de senha enviada para o cliente'
            )
            this.disableResetPassword = false
          })
          .catch(() => {
            this.$vueOnToast.pop(
              'error',
              'Erro ao enviar redefinição de senha ao cliente'
            )
            this.disableResetPassword = false
          })
      }
    },
    sendVerificacao(canal) {
      this.$store
        .dispatch(CLIENTE_VERIFICACAO, {
          canal: canal,
          idcliente: this.idcliente,
          fonte: 'manual'
        })
        .then(() => {
          this.$vueOnToast.pop(
            'success',
            'Reenvio de verificação realizado com sucesso'
          )
          this.disableResetPassword = false
        })
        .catch(() => {
          this.$vueOnToast.pop(
            'error',
            'Erro ao reenviar verificação ao cliente'
          )
        })
    },
    onClose() {
      this.$emit('close')
    },
    copiar() {
      let testingCodeToCopy = document.querySelector('#testing-code')
      testingCodeToCopy.setAttribute('type', 'text') // Wtf (╯°□°）╯︵ ┻━┻  不是 hidden 才能複製
      testingCodeToCopy.select()
      document.execCommand('copy')
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    onSearchAtendente(value) {
      ApiWeb.v4.usuario
        .pesquisa(this.getFornecedorId, value || '')
        .then(resp => {
          this.atendentesResult = _.uniqBy(
            this.atendentesResult.concat(resp.data),
            'idusuario'
          )
        })
    },
    disableFieldCpfCnpj(cpf) {
      const cnpjCpfClear = cpf ? cpf.replace(/\D/g, '') : null
      this.disableFieldBirthday = cnpjCpfClear
        ? cnpjCpfClear.length === 14
          ? true
          : false
        : false
    }
  },
  watch: {
    'form.permitir_alteracao_campos_erp': {
      deep: true,
      handler(newVal) {
        this.form.ignorar_alteracao_erp = _.difference(
          this.camposIgnoraveisIntegracao,
          newVal
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.reset-senha-container {
  width: 100%;
  text-align: left;
}

.codigo_grupo_preco {
  display: flex;
  align-items: center;
  margin-top: -13px;
  margin-bottom: 20px;
}
.codigolabel {
  font-size: 13px;
}
.botaocopiar {
  margin-left: 21px;
  width: 40px;
  height: 30px !important;
  font-size: 11px !important;
}
.phone-btn {
  margin-left: 5px;
  font-size: 15px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}
</style>
