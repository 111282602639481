<template>
  <div>
    <!-- <div v-for="item in caracteristicas" :key="item.idcaracteristica">
      <FormSwitch
        v-if="item.tipo === 'boolean'"
        v-model="item.descricao"
        :validation="getValidationField(item.idcaracteristica, 'descricao')"
        :label="item.text"
      />
      <FormField
        v-else
        :label="item.text"
        :validation="getValidationField(item.idcaracteristica, 'descricao')"
        v-model="item.descricao"
      />
    </div> -->
    <v-row v-for="(item, index) in caracteristicas" :key="index">
      <v-col class="d-flex justify-center" cols="12" xs="12" sm="1" md="1">
        <FormButton icon type="perigo" @click="remove(item.idcaracteristica)">
          <v-icon size="18">fas fa-times</v-icon>
        </FormButton>
      </v-col>
      <v-col cols="12" xs="12" sm="5" md="5">
        <FormAutocomplete
          label="Característica"
          v-model="item.idcaracteristica"
          :options="options"
          :validation="
            getValidationField(item.idcaracteristica, 'idcaracteristica')
          "
          class="form-inline-item"
        />
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6">
        <FormSwitch
          style="margin-top: 0px;"
          v-if="checkBoolean(item)"
          v-model="item.descricao"
          :validation="getValidationField(item.idcaracteristica, 'descricao')"
          :label="item.text"
        />
        <FormField
          v-else
          :label="item.text"
          :validation="getValidationField(item.idcaracteristica, 'descricao')"
          v-model="item.descricao"
        />
      </v-col>
    </v-row>
    <v-row justify="center">
      <FormButton type="primario" @click="add" text="Nova característica" />
    </v-row>
  </div>
</template>

<script>
import FormAutocomplete from '@/components/form/FormAutocomplete'
import FormButton from '@/components/form/FormButton'
import FormSwitch from '@/components/form/FormSwitch'
import FormField from '@/components/form/FormField'
import { mapGetters } from 'vuex'
import * as _ from 'lodash'

export default {
  name: 'ClienteFormCaracteristica',
  components: { FormField, FormSwitch, FormAutocomplete, FormButton },
  props: {
    value: {
      type: Array,
      default: () => [],
      required: true
    },
    validation: {
      type: Object,
      default: () => null,
      required: false
    }
  },
  data: () => ({
    caracteristicas: [
      {
        idcaracteristica: '',
        obrigatorio: '',
        descricao: '',
        tipo: '',
        text: '',
        id: Math.random() * 10000
      }
    ]
  }),
  computed: {
    ...mapGetters(['getListCaracteristicasCliente']),
    options() {
      return this.getListCaracteristicasCliente.map(item => ({
        text: item.nome,
        value: item.idcaracteristica,
        idcaracteristica: item.idcaracteristica,
        obrigatorio: item.obrigatorio,
        descricao: item.descricao,
        tipo: item.tipo
      }))
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.caracteristicas = newVal
      }
    }
  },
  created() {
    if (this.value) {
      this.caracteristicas = this.value
    }
  },
  methods: {
    add() {
      this.caracteristicas.push({
        idcaracteristica: '',
        descricao: '',
        obrigatorio: false,
        tipo: 'string',
        id: Math.random() * 10000
      })
    },
    checkBoolean(item) {
      const cara = (this.options || []).find(
        c => c.idcaracteristica === item.idcaracteristica
      )
      return cara && cara.tipo === 'boolean'
    },
    remove(id) {
      this.caracteristicas = this.caracteristicas.filter(
        item => item.idcaracteristica != id
      )
      this.onChange()
    },
    onChange() {
      this.$emit('input', this.caracteristicas)
      this.$emit('change', this.caracteristicas)
    },
    getValidationField(id, columns) {
      if (this.validation && this.validation.$each) {
        for (const i in this.validation.$each.$iter) {
          let val = this.validation.$each.$iter[i]
          if (_.get(val, '$model.idcaracteristica', '') == id) {
            return _.get(val, columns, null)
          }
        }
      }
      return null
    }
  }
}
</script>
